import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Container } from "@mui/system";

const DaysSummary = (props) => {
  // aca parseo todo las cosas de event_days
  var arr = [];
  var parsed = [];
  var date_csv = [];
  var disc_parsed = [];

  Object.keys(props.csv.event_list).forEach(function (key) {
    arr.push(props.csv.event_list[key]);
  });

  Object.keys(arr).forEach(function (key) {
    parsed.push(arr[key]);
  });

  Math.trunc = function (n) {
    return n < 0 ? Math.ceil(n) : Math.floor(n);
  };
  Math.frac = function (n) {
    return n - Math.trunc(n);
  };
  Math.modf = function (n) {
    return [Math.trunc(n), Math.frac(n)];
  };

  function parseHours(n) {
    var temp1 = n / 60;
    var result_result = Math.modf(temp1);
    var hrs_result = result_result[0];
    var temp_1 = result_result[1] * 60;
    var temp_11 = Math.modf(temp_1);
    var min_result = temp_11[0];
    var second_result = (temp_11[1] * 60).toFixed(0);
    return [hrs_result, min_result, second_result];
  }

  Object.keys(parsed).forEach(function (key) {
    var other_date =
      parsed[key].day + "/" + parsed[key].month + "/" + parsed[key].year;

    date_csv.push(other_date);
  });

  function lowerThan(n) {
    var stringedNum = n.toString();

    if (n < 10) {
      return "0" + stringedNum;
    } else {
      return stringedNum;
    }
  }

  function parse_disc(disc) {
    var temp = parseHours(disc);
    var hour = lowerThan(temp[0]);
    var min = lowerThan(temp[1]);
    var sec = lowerThan(temp[2]);
    var returnString = hour + ":" + min + ":" + sec;
    return returnString;
  }
  console.log(parsed);
  return (
    <Container sx={{ my: 3 }}>
      <Box>
        <Box sx={{ mt: 3 }} align="center">
          <h6
            style={{
              textAlign: "center",
              fontFamily: "Noto Sans Display",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: 18,
            }}
          >
            Daily Summary
          </h6>
        </Box>
        <TableContainer sx={{ mb: 3 }} component={Paper} centered="true">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Logged in At</TableCell>
                <TableCell>Logged out At</TableCell>
                <TableCell>Productivity</TableCell>
                <TableCell>Login Time</TableCell>
                <TableCell>On-Call Time</TableCell>
                <TableCell>Offline Time </TableCell>
                <TableCell>Offline Factor</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsed.map((element, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{date_csv[index]} </TableCell>
                  <TableCell align="left">{element.first_login}</TableCell>
                  <TableCell align="left">{element.last_logout}</TableCell>
                  <TableCell align="left">
                    {element.productivity.toFixed(2)}
                    {"%"}
                  </TableCell>
                  <TableCell align="left">{element.login_time}</TableCell>
                  <TableCell align="left">{element.on_call}</TableCell>
                  <TableCell align="left">
                    {parse_disc(element.time_desc)}
                  </TableCell>
                  <TableCell align="left">
                    {element.disconnected.toFixed(2)}
                    {"%"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default DaysSummary;
