import { Navigate, Outlet } from "react-router-dom";
import { DASHBOARD } from "../../config/routes/paths";
import { useAuthContext } from "../../contexts/authContext";

export default function PublicRoute() {
  const { isAuthenticated } = useAuthContext();
  console.log(isAuthenticated);
  
  if (isAuthenticated) {

    return <Navigate to={DASHBOARD} />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
