import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/router/PrivateRoute";
import PublicRoute from "./components/router/PublicRoute";
import { DASHBOARD, LOGOUT, REGISTER } from "./config/routes/paths";
import { AuthContextProvider } from "./contexts/authContext";
import Dashboard from "./views/Dashboard";
import Register from "./views/Register";
import Login from "./views/Login";
import Logout from "./views/Logout";
import Sidebar from './components/Navbar/Sidebar';
import useState from 'react'


function App() {
	return (
    <div>
		{/* <div className={`app ${toggled ? 'toggled' : ''}`}> */}
      {/* <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      /> */}

			<main>
				<AuthContextProvider>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<PublicRoute />}>
								<Route index element={<Login />} />
							</Route>

							<Route path={DASHBOARD} element={<PrivateRoute />}>
								<Route index element={<Dashboard />} />
								<Route path={LOGOUT} element={<Logout />} />
							</Route>

							<Route path={REGISTER} element={<PrivateRoute />}>
								<Route index element={<Register />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</AuthContextProvider>
			</main>
		</div>
	);
}
export default App;
