import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";
import { Bar } from "react-chartjs-2";

const BarGraph = (props) => {
  var arr = [];
  var parsed = [];
  var date_csv = [];
  var answered_calls = [];
  var unanswered_calls = [];
  var busy_calls = [];

  Object.keys(props.csv.event_list).forEach(function (key) {
    arr.push(props.csv.event_list[key]);
  });

  Object.keys(arr).forEach(function (key) {
    parsed.push(arr[key]);
  });

  Object.keys(parsed).forEach(function (key) {
    var other_date =
      parsed[key].day + "/" + parsed[key].month + "/" + parsed[key].year;
    date_csv.push(other_date);
    //var this_date = new Date(parsed[key].year, parsed[key].month-1, parsed[key].day);

    var answered = parsed[key].daily_answered;
    var unanswered = parsed[key].daily_unanswered;
    var busy = parsed[key].daily_busy;

    answered_calls.push(answered);
    unanswered_calls.push(unanswered);
    busy_calls.push(busy);
  });

  return (
    <Box sx={{ mt: 5, md: 3 }}>
      <h6
        style={{
          textAlign: "center",
          fontFamily: "Noto Sans Display",
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          fontSize: 18,
        }}
      >
        Call Summary
      </h6>
      <Bar
        data={{
          labels: date_csv,
          datasets: [
            {
              label: "Answered Calls",
              data: answered_calls,
              fill: true,
              backgroundColor: "green",
            },
            {
              label: "Unanswered Calls",
              data: unanswered_calls,
              fill: true,
              backgroundColor: "red",
            },
            {
              label: "Busy Calls",
              data: busy_calls,
              fill: true,
              backgroundColor: "yellow",
            },
          ],
        }}
      />
      <Box align="center" sx={{ mt: 3 }}>
        <TableContainer sx={{ width: 400 }} component={Paper} centered="true">
          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: 70 }}> Dates </TableCell>
                <TableCell sx={{ width: 70 }}> Answered Calls </TableCell>
                <TableCell sx={{ width: 70 }}> Unanswered Calls </TableCell>
                <TableCell sx={{ width: 70 }}> Busy Calls </TableCell>
              </TableRow>
              {parsed.map((element, index) => (
                <TableRow key={index}>
                  <TableCell> {date_csv[index]} </TableCell>
                  <TableCell> {answered_calls[index]}</TableCell>
                  <TableCell> {unanswered_calls[index]}</TableCell>
                  <TableCell> {busy_calls[index]} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default BarGraph;
