import { useAuthContext } from "../contexts/authContext";
import React, { useState } from "react";
import axios from "axios";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import bl_white from "../img/bl_white.png";
import { Container } from "@mui/system";

import "./style.css";

function Login() {
	const [isError, setIsError] = useState(false);
	const [err_state, setErrState] = useState("no error");

	const [usernameReg, setUsernameReg] = useState("");
	const [passwordReg, setPasswordReg] = useState("");

	// const [error, setError] = useState();
	const { login } = useAuthContext();
	const backendResponse = React.useRef("");

	const setStatusLogin = () => {
		// console.log(backendResponse);
		if (backendResponse.current === "Allowed") {
			login();
		} else {
			console.log("setting error...");
			setErrState("Please enter a valid username or password");
			setIsError(true);
		}
	};

	const setErrStatus = () => {
		console.log(backendResponse);
		console.log("setting error...");
		setErrState(backendResponse.data);
	};

	const attempredReg = async (e) => {
		e.preventDefault();
		const f = new FormData();
		f.append("username", usernameReg);
		f.append("password", passwordReg);

	// const url = "http://localhost:5000/api/login"; // dev
	const url = "https://app.neptuno.cloudbilingual.com/api/login"; // prod
		await axios
			.post(url, f, {
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				},
			})
			.then((res) => {
				console.log("res.data:", res.data);
				backendResponse.current = res.data;
				setStatusLogin();
			})
			.catch((err) => {
				console.log(err);
				backendResponse.current = err.response;
				setErrStatus();
			});
	};

	return (
		<Container>
			<Grid container>
				<Grid item xs={6}>
					<div className="Box-container">
						<div className="image-container">
							<img className="image-logo" src={bl_white} />
						</div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className="login-section">
						<p className="login-text"> LOGIN </p>
						<Box
							component="form"
							autoComplete="on"
							noValidate
							onSubmit={attempredReg}
						>
							{isError ? (
								<Box sx={{ my: "10px", width: "155%" }}>
									<Alert severity="error">{err_state}</Alert>
									{/* Please enter a valid username or password */}
								</Box>
							) : null}
							<div>
								<p className="text-space">Email</p>
								<TextField
									className="text-input"
									required
									id="outlined-required"
									placeholder="Email address"
									onChange={(e) => {
										setUsernameReg(e.target.value);
									}}
								/>
								<p className="text-space">Password</p>
								<TextField
									className="text-input"
									required
									id="outlined-password-input"
									type="password"
									placeholder="Password"
									onChange={(e) => {
										setPasswordReg(e.target.value);
									}}
								/>
							</div>
							<Button
								className="login-button"
								type="submit"
								sx={{
									my: "2em",
									backgroundColor: "#3D3D8F",
									borderColor: "#3D3D8F",
									borderRadius: "4px",
									color: "white",
									"&:hover": {
										background: "#6464A5",
									},
								}}
							>
								Login
							</Button>
						</Box>
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}

export default Login;
