import { Container, TextField } from "@mui/material";
import { Button } from "@mui/material";
import { LOGOUT } from "../../config/routes/paths";

import { Grid, Typography, Stack, Divider, Item } from "@mui/material";
import React from "react";
import bl_color_cut from "../../img/bl_color_cut.png";
import "./style.css";
import AppBar from "@mui/material/AppBar";

import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom";


function Appbar() {
  return (
    <AppBar style={{ background: '#FFFFFF', marginBottom: "20px", maxHeight: '60px' }}>
      <Toolbar>
        <img style={{ height: '50px', objectFit: 'contain', padding: '20px' }} src={bl_color_cut} alt="React Logo" />
        {/* <Divider orientation="vertical" flexItem /> */}
        <Typography
          variant="h5"
          component="a"
          noWrap

          sx={{
            mr: 2,
            fontFamily: "Noto Sans Display",
            fontWeight: 600,
            color: "#3d3d8f",
            textDecoration: "none",
            marginLeft: "20px",
            flexGrow: 1,
          }}
        >
          Connectivity Report Generation
        </Typography>

        <Link to={LOGOUT}>
          <Button
            sx={{
              color: "#3d3d8f",
              borderColor: "#3d3d8f",
              mx: 2,
              textAlign: "center",
              opacity: 1,
              "&:hover": {
                borderColor: "#3D3D8F",
                backgroundColor: "#ededf7",
              },
            }}
            variant="outlined"
            size="small"
          >
            Log out
          </Button>
        </Link>


      </Toolbar>
    </AppBar>
  );
}

export default Appbar;
