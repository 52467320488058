import { Button, Box, TextField, Typography, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { LOGOUT } from "../config/routes/paths";
import FileForm from "../components/Form/FileForm";
import Appbar from "../components/Navbar/Appbar";
import InputAdornment from '@mui/material/InputAdornment';

const geos = [
  {
    value: 'PE',
    label: 'PE',
  },
  {
    value: 'COL',
    label: 'COL',
  },
  {
    value: 'MEX',
    label: 'MEX',
  },
];

const providers = [
  {
    value: 'Bandwidth',
    label: 'Bandwidth',
  },
  {
    value: 'Fusion',
    label: 'Fusion',
  },
];

function Register() {
	return (
		<div>
			<Appbar />

			<div className="page-content">
				<Box
					component="form"
					sx={{
						"& .MuiTextField-root": { m: 1, width: "25ch" },
					}}
					noValidate
					autoComplete="off"
					width="60%"
				>
					<Typography
						variant="h5"
						component="a"
						noWrap
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontFamily: "Noto Sans Display",
							fontWeight: 600,
							color: "inherit",
							textDecoration: "none",
						}}
					>
						Register new interpreter
					</Typography>
					<div>
						<TextField
							required
							id="interpreter-name"
							label="Interpreter Name"
							defaultValue="John Doe"
						/>
						<TextField
							id="geo-location"
							select
							label="Geo"
							defaultValue="PE"
							helperText="Select Geolocation"
						>
							{geos.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
            
            <TextField
							required
							id="cli-code"
							label="ID (CLI)"
							defaultValue="BL123"
						/>
            
            <TextField
							id="annex"
							label="Annex"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
						/>
            <TextField
							id="did"
							label="DID"
							type="number"
							InputLabelProps={{
								shrink: true,
							}}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
						/>
            <TextField
							id="provider"
							select
							label="Provider"
							defaultValue="Bandwidth"
							helperText="Select Provider"
						>
							{providers.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
            
						<TextField
							id="device"
							label="Device"
							defaultValue="N/A"
							InputProps={{
								readOnly: true,
							}}
						/>
            <TextField
							id="status"
							label="Status"
							defaultValue={0}
							InputProps={{
								readOnly: true,
							}}
						/>
					</div>
				</Box>
			</div>
		</div>
	);
}

export default Register;
