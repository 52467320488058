import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { LOGOUT } from "../config/routes/paths";
import FileForm from "../components/Form/FileForm";
import Appbar from "../components/Navbar/Appbar";
import MessageComponent from "../components/Message/MessageComponent";

function Dashboard() {
  const [err_state, setErrState] = useState([]);

  return (
    <div>
      <Appbar />
      <div className="page-content">
        <MessageComponent messages={err_state} />
        <FileForm err_state={err_state} setErrState={setErrState}></FileForm>
      </div>
    </div>
  );
}

export default Dashboard;
