import React, { useRef, useState } from "react";
import axios from "axios";
import LineGraph from "../Graph/LineGraph";
import { Button, Container, Grid, Stack } from "@mui/material";
import BarGraph from "../Graph/BarGraph";
import PieChartDisc from "../Graph/PieChartDisc";
import DaysSummary from "../Graph/DaysSummary";
import { useReactToPrint } from "react-to-print";
import { Item } from "semantic-ui-react";
import List from '@mui/material/List';
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

const FileForm = ({ err_state, setErrState }) => {
  const [fileSent, setfileSent] = useState(false);
  const [files, setFiles] = useState(null);
  const [usedNames, setUsedNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filesNames, setFilesNames] = useState(false);
  const [dataPoints, setDataPoints] = useState(null);
  const componentRef = useRef(null);
  const hiddenFileInput = React.useRef(null);

  const checkPrint = () => {
    if (componentRef.current == null) {
      setErrState(["No report to print."]);
      return;
    }
    handlePrint();
  };


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      console.log(dataPoints)
      document.title = "Neptuno_Report_" + dataPoints.interpreter.fname + '_' + dataPoints.interpreter.lname
    },
    onAfterPrint: () => { document.title = "Bilingual | Neptuno" },
  });

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const submitFiles = (e) => {
    setFiles(e);
  };

  const setData = (e) => {
    setDataPoints(e);
  };

  const uploadFiles = async () => {
    if (files == null) {
      setErrState(["Missing files to upload."]);
      // throw new Error('Missing files to upload.');
      return;
    }
    setLoading(true)

    const f = new FormData();
    for (let i = 0; i < files.length; i++) {
      f.append("files", files[i]);
    }

    // const url = "http://localhost:5000/api/data"; // dev
    const url = '/api/data'; // prod
    await axios
      .post(url, f, {
        method: 'post',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((res) => {
        console.log(res.data)
        setData(res.data);
        setfileSent(true);
        setLoading(false)

      })
      .catch((err) => {
        console.log("notifying error:")
        console.log(err);
        setLoading(false)
        console.log(err.code);

        if (err.code === "ERR_NETWORK")
          setErrState(["Server network error."]);
        else
          setErrState([err.response.data]);

      });
    setLoading(false)
  };

  return (
    <div>
      <Stack
        direction="column"
        spacing={1.5}
        alignItems="center"
      >
        <Container maxWidth="sm" >
          <Stack
            direction="column"
            spacing={1.5}
          >
            <Card elevation={6} sx={{ padding: "12px", }}>
              <Stack
                direction="row"
                spacing={1.5}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography gutterBottom variant="h6" color="text.secondary" component="div">
                  External tools
                </Typography>

                <Divider orientation="vertical" variant="middle" flexItem />
                {/* <Divider variant="middle" sx={{ marginBottom: "10px" }} /> */}
                <Button
                  sx={{
                    backgroundColor: '#3d3d8f',
                    mx: 2,
                    my: 2,
                    textAlign: "center",
                    "&:hover": {
                      borderColor: "#3d3d8f",
                      backgroundColor: "#ededf7",
                      color: "#3d3d8f",
                    },
                  }}
                  variant="contained"
                  size="small"
                  rel="noopener noreferrer"
                  href="https://app.data.cloudbilingual.com/interpreter-event-log/report"
                  target="_blank"
                >
                  AppData Reports
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#3d3d8f',
                    mx: 2,
                    my: 2,
                    textAlign: "center",
                    "&:hover": {
                      borderColor: "#3d3d8f",
                      backgroundColor: "#ededf7",
                      color: "#3d3d8f",
                    },
                  }}
                  variant="contained"
                  size="small"
                  rel="noopener noreferrer"
                  href="https://apolo.cloudbilingual.com/app/cli-annex-form#/interpreters"
                  target="_blank"
                >
                  CLI Annex Form
                </Button>
              </Stack>
              {/* </CardContent> */}
            </Card>

            <Card elevation={6}>
              <CardContent>
                <Typography gutterBottom variant="h5" color="text.secondary" component="div" sx={{ mx: 2 }}>
                  Generate a report
                </Typography>

                <Divider variant="middle" sx={{ marginBottom: "10px" }} />

                <Button
                  onClick={handleClick}
                  sx={{
                    marginBottom: "10px",
                    marginTop: "5px",
                    color: "#3d3d8f",
                    borderColor: "#3d3d8f",
                    mx: 2,
                    textAlign: "center",
                    opacity: 1,
                    "&:hover": {
                      borderColor: "#3D3D8F",
                      backgroundColor: "#ededf7",
                    },

                  }}
                  variant="outlined"
                  startIcon={<DriveFolderUploadIcon />}
                >
                  {" "}
                  Select Files
                  <input
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    type="file"
                    name="files"
                    multiple
                    onChange={(e) => {
                      submitFiles(e.target.files);
                      setFilesNames(true);
                    }}
                  ></input>
                </Button>

                {filesNames === true &&
                  <List dense={true}>
                    {Array.from(files).map((item) => (
                      <ListItem key={item.name}>
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItem>
                    ))}
                  </List>
                }
                {filesNames === true &&
                  <Button
                    sx={{
                      margin: "0px",
                      backgroundColor: '#3d3d8f',
                      mx: 2,
                      my: 2,
                      textAlign: "center",
                      "&:hover": {
                        borderColor: "#3d3d8f",
                        backgroundColor: "#ededf7",
                        color: "#3d3d8f",
                      },
                    }}
                    variant="contained"
                    onClick={(e) => {
                      uploadFiles();
                    }}
                    component="label"
                    startIcon={loading === false && <CloudUploadIcon /> || loading === true && <CircularProgress color="inherit" size="1.5rem" />}
                  >
                    Upload Files
                  </Button>
                }
                {fileSent === true && (
                  <Button
                    sx={{
                      backgroundColor: '#de231b',
                      mx: 2,
                      my: 2,
                      textAlign: "center",
                      "&:hover": {
                        borderColor: "#de231b",
                        backgroundColor: "#ededf7",
                        color: "#de231b",
                      },
                    }}
                    variant="contained"
                    onClick={checkPrint}

                    component="label"
                    startIcon={<DescriptionIcon />}
                  >
                    {" "}
                    Export PDF
                  </Button>
                )}

              </CardContent>
            </Card>
          </Stack>

        </Container>

        {fileSent === true && (
          <Container
            ref={componentRef}
            sx={{
              border: 1,
              borderRadius: 1,
              borderColor: "#e6e6e6",
            }}
            maxWidth="md"
          >
            <Stack spacing={2}>
              <Item>
                <LineGraph csv={dataPoints} key="1" />
              </Item>
              <Item>
                <BarGraph csv={dataPoints} key="2" />
              </Item>
              <Item>
                <PieChartDisc csv={dataPoints} key="3" />
              </Item>
              <Item>
                <DaysSummary csv={dataPoints} key="4" />
              </Item>
            </Stack>
          </Container>
        )}
      </Stack>

    </div>
  );
};

export default FileForm;
