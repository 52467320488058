import React from "react";
import { Line, Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const LineGraph = (props) => {
  var arr = [];
  var parsed = [];
  var date_csv = [];
  var productivity = [];
  var disconnections = [];

  Object.keys(props.csv.event_list).forEach(function (key) {
    arr.push(props.csv.event_list[key]);
  });

  Object.keys(arr).forEach(function (key) {
    parsed.push(arr[key]);
  });

  Math.trunc = function (n) {
    return n < 0 ? Math.ceil(n) : Math.floor(n);
  };
  Math.frac = function (n) {
    return n - Math.trunc(n);
  };
  Math.modf = function (n) {
    return [Math.trunc(n), Math.frac(n)];
  };

  function lowerThan(n) {
    var stringedNum = n.toString();

    if (n <= 10) {
      return "0" + stringedNum;
    } else {
      return stringedNum;
    }
  }

  function parseHours(n) {
    var temp1 = n / 60;
    var result_result = Math.modf(temp1);
    var hrs_result = result_result[0];
    var temp_1 = result_result[1] * 60;
    var temp_11 = Math.modf(temp_1);
    var min_result = temp_11[0];
    var second_result = (temp_11[1] * 60).toFixed(0);
    return [hrs_result, min_result, second_result];
  }

  function parse_time(unparsed) {
    var temp = parseHours(unparsed);
    var hour = lowerThan(temp[0]);
    var min = lowerThan(temp[1]);
    var sec = lowerThan(temp[2]);
    var returnString = hour + ":" + min + ":" + sec;
    return returnString;
  }
  let total_calls =
    props.csv.answered_calls +
    props.csv.unanswered_calls +
    props.csv.busy_calls +
    props.csv.failed_calls;
  let answr_rate = (props.csv.answered_calls * 100) / total_calls;
  var temp1 = parse_time(props.csv.login_total);
  var temp2 = parse_time(props.csv.disc_total_time);
  var temp3 = parse_time(props.csv.oncall_total);

  Object.keys(parsed).forEach(function (key) {
    var other_date =
      parsed[key].day + "/" + parsed[key].month + "/" + parsed[key].year;
    //var this_date = new Date(parsed[key].year, parsed[key].month-1, parsed[key].day);

    var this_disc = parsed[key].disconnected;
    var this_prod = parsed[key].productivity;
    productivity.push(this_prod);
    disconnections.push(this_disc);
    date_csv.push(other_date);
  });

  return (
    <Box sx={{ md: 3, mt: 3 }}>
      {/* Interpreter Summary */}
      <Box>
        <TableContainer component={Paper} centered="true">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="cell" align="left">
                  Analyzed Interpreter:{" "}
                </TableCell>
                <TableCell align="left">
                  {" "}
                  {props.csv.interpreter.fname} {props.csv.interpreter.lname}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="cell" align="left">
                  Interpreter Code:{" "}
                </TableCell>
                <TableCell align="left">
                  {" "}
                  {props.csv.interpreter.interpreter_code}{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="cell" align="left">
                  Period:{" "}
                </TableCell>
                <TableCell align="left">
                  {date_csv[0]} to {date_csv.at(-1)}{" "}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ my: 3 }} component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left">Login time: </TableCell>
                <TableCell align="left"> {temp1} </TableCell>
                <TableCell align="left">On-call time: </TableCell>
                <TableCell align="left"> {temp3} </TableCell>
                <TableCell align="left">Offline time: </TableCell>
                <TableCell align="left">{temp2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"> Average Productivity: </TableCell>
                <TableCell align="left">
                  {" "}
                  {props.csv.productivity.toFixed(2)}%{" "}
                </TableCell>
                <TableCell align="left">Offline Factor: </TableCell>
                <TableCell align="left">
                  {props.csv.disconnections_total.toFixed(2)}%{" "}
                </TableCell>
                <TableCell align="left">Trustworthiness</TableCell>
                <TableCell align="left">
                  {props.csv.trusty.toFixed(2)}
                  {"%"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ my: 3 }} component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Worked Days:</TableCell>
                <TableCell>{props.csv.total_days}</TableCell>
                <TableCell>Recieved Calls:</TableCell>
                <TableCell>{total_calls}</TableCell>
                <TableCell>Answered Calls:</TableCell>
                <TableCell>{props.csv.answered_calls}</TableCell>
                <TableCell>Answer Rate:</TableCell>
                <TableCell>
                  {answr_rate.toFixed(2)}
                  {"%"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Line Graph */}
      <h6
        style={{
          textAlign: "center",
          fontFamily: "Noto Sans Display",
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          fontSize: 18,
        }}
      >
        Productivity and Offline Summary
      </h6>
      {date_csv.length < 1 ? (
        <Bar
          options={{
            scales: {
              y: {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                title: {
                  display: true,
                  fontFamily: "Noto Sans Display",
                  text: "Percentages",
                },
              },
              x: {
                title: {
                  display: true,
                  fontFamily: "Noto Sans Display",
                  text: "Date",
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || "";
                    if (context.parsed.y !== null) {

                      label += " " + (+(Math.round(context.parsed.y + "e+2") + "e-2")) + "%";
                    }
                    return label;
                  },
                },
              },
            },
          }}
          data={{
            labels: date_csv,
            datasets: [
              {
                label: "Productivity",
                // tension: 0.1,
                data: productivity,
                fill: true,
                // hoverBorderWidth: 3,
                // pointHoverRadius: 5,
                backgroundColor: "rgba(75,192,192,0.2)",
                // borderColor: "rgba(75,192,192,1)",
              },
              {
                label: "Offline",
                // tension: 0.1,
                data: disconnections,
                fill: true,
                // hoverBorderWidth: 3,
                // pointHoverRadius: 5,
                backgroundColor: "rgba(250, 178, 165,0.7)",
                // borderColor: "#e03448",
              },
            ],
          }}
        />
      ) : (
        <Line
          options={{
            scales: {
              y: {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index, ticks) {
                    return value + "%";
                  },
                },
                title: {
                  display: true,
                  fontFamily: "Noto Sans Display",
                  text: "Percentages",
                },
              },
              x: {
                title: {
                  display: true,
                  fontFamily: "Noto Sans Display",
                  text: "Date",
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || "";
                    if (context.parsed.y !== null) {

                      label += " " + (+(Math.round(context.parsed.y + "e+2") + "e-2")) + "%";
                    }
                    return label;
                  },
                },
              },
            },
          }}
          data={{
            labels: date_csv,
            datasets: [
              {
                label: "Productivity",
                tension: 0.1,
                data: productivity,
                fill: true,
                hoverBorderWidth: 3,
                pointHoverRadius: 5,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
              },
              {
                label: "Offline",
                tension: 0.1,
                data: disconnections,
                fill: true,
                hoverBorderWidth: 3,
                pointHoverRadius: 5,
                backgroundColor: "rgba(250, 178, 165,0.7)",
                borderColor: "#e03448",
              },
            ],
          }}
        />
      )}

      <TableContainer sx={{ mb: 3 }} component={Paper} centered="true">
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell> Dates </TableCell>
              <TableCell> Productivity % </TableCell>
              <TableCell> Offline % </TableCell>
              <TableCell> Disconnection Incidents </TableCell>
            </TableRow>
            {parsed.map((element, index) => (
              <TableRow key={index}>
                <TableCell> {date_csv[index]} </TableCell>
                <TableCell>
                  {" "}
                  {productivity[index].toFixed(2)}
                  {"%"}
                </TableCell>
                <TableCell>
                  {" "}
                  {disconnections[index].toFixed(2)}
                  {"%"}
                </TableCell>
                <TableCell> {element.counter}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Line Graph */}
      <h6
        style={{
          textAlign: "center",
          fontFamily: "Noto Sans Display",
          letterSpacing: ".3rem",
          color: "inherit",
          textDecoration: "none",
          fontSize: 18,
        }}
      >
        Disconnection Events List
      </h6>
      <TableContainer sx={{ mb: 3 }} component={Paper} centered="true">
        <Table size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell> Event Date </TableCell>
              <TableCell> Disconnected at </TableCell>
              <TableCell> Reconnected at </TableCell>
              <TableCell> Time disconnected </TableCell>
            </TableRow>
            {props.csv.disconnection_list.map((element, index) => (
              <TableRow key={index}>
                <TableCell> {element[0]} </TableCell>
                <TableCell> {element[1]} </TableCell>
                <TableCell> {element[2]} </TableCell>
                <TableCell> {element[3]} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LineGraph;
