import {
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Doughnut } from "react-chartjs-2";

const PieChartDisc = (props) => {
  var arr = [];
  var parsed = [];

  var login_time_sum = 0;
  var disc_time_sum = 0;

  var colors = ["Answered Calls", "Unanswered Calls", "Busy Calls"];
  var calls = [
    props.csv.answered_calls,
    props.csv.unanswered_calls,
    props.csv.busy_calls,
  ];

  Object.keys(props.csv.event_list).forEach(function (key) {
    arr.push(props.csv.event_list[key]);
  });

  Object.keys(arr).forEach(function (key) {
    parsed.push(arr[key]);
  });

  Object.keys(parsed).forEach(function (key) {
    var this_disc = parsed[key].time_desc;
    var this_prod = parsed[key].login_prod;
    login_time_sum += this_prod;
    disc_time_sum += this_disc;
  });
  Math.trunc = function (n) {
    return n < 0 ? Math.ceil(n) : Math.floor(n);
  };
  Math.frac = function (n) {
    return n - Math.trunc(n);
  };
  Math.modf = function (n) {
    return [Math.trunc(n), Math.frac(n)];
  };

  function lowerThan(n) {
    var stringedNum = n.toString();

    if (n <= 10) {
      return "0" + stringedNum;
    } else {
      return stringedNum;
    }
  }

  function parseHours(n) {
    var temp1 = n / 60;
    var result_result = Math.modf(temp1);
    var hrs_result = result_result[0];
    var temp_1 = result_result[1] * 60;
    var temp_11 = Math.modf(temp_1);
    var min_result = temp_11[0];
    var second_result = (temp_11[1] * 60).toFixed(0);
    return [hrs_result, min_result, second_result];
  }

  function parse_time(unparsed) {
    var temp = parseHours(unparsed);
    var hour = lowerThan(temp[0]);
    var min = lowerThan(temp[1]);
    var sec = lowerThan(temp[2]);
    var returnString = hour + ":" + min + ":" + sec;
    return returnString;
  }

  var temp1 = parse_time(props.csv.login_total);
  var temp2 = parse_time(props.csv.disc_total_time);

  var time_disposition = ["Login time", "Disconnected time"];
  var reg_login = login_time_sum - disc_time_sum;
  var calls_time = [reg_login, disc_time_sum];

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box>
            <h6
              style={{
                fontFamily: "Noto Sans Display",
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 18,
              }}
            >
              Call Relations
            </h6>
            <Doughnut
              data={{
                labels: colors,
                datasets: [
                  {
                    label: "Call Relations",
                    data: calls,
                    fill: true,
                    backgroundColor: [
                      "rgba(33, 176, 57,0.9)",
                      "rgb(222, 27, 27,0.9)",
                      "rgb(247, 207, 2,0.9)",
                    ],
                  },
                ],
                hoverOffset: 4,
              }}
            />
            <Box align="center">
              <TableContainer
                sx={{ width: 300 }}
                component={Paper}
                centered="true"
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"> Answered Calls </TableCell>
                      <TableCell align="left"> Unanswered Calls </TableCell>
                      <TableCell align="left"> Busy Calls </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        {" "}
                        {props.csv.answered_calls}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {props.csv.unanswered_calls}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        {props.csv.busy_calls}{" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box sx={{ width: "100%" }}>
            <h6
              style={{
                fontFamily: "Noto Sans Display",
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 18,
              }}
            >
              Time Disconnected
            </h6>
            <Doughnut
              data={{
                labels: time_disposition,
                datasets: [
                  {
                    label: "Time Disconnected",
                    data: calls_time,
                    fill: true,
                    backgroundColor: ["blue", "violet"],
                  },
                ],
                hoverOffset: 4,
              }}
            />
            <Box align="center">
              <TableContainer
                sx={{ width: 300 }}
                component={Paper}
                centered="true"
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Login Time </TableCell>
                      <TableCell> Offline Time </TableCell>
                      <TableCell> Offline Factor </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell> {temp1}</TableCell>
                      <TableCell> {temp2} </TableCell>
                      <TableCell>
                        {" "}
                        {props.csv.disconnections_total.toFixed(2)}
                        {"%"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PieChartDisc;
