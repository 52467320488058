// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-base{
    position: absolute;
    width: 100%;
    height: 64px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 64px;

}

.logo-image{
    margin: 0.5em;
    padding-left: 20px;
    width: 14em;
}

.image-container{
}

.appbar-text{
    font-family: 'Noto Sans Display';
    font-size: 30px;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,QAAQ;IACR,mBAAmB;IACnB,2CAA2C;IAC3C,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;AACf;;AAEA;AACA;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,SAAS;AACb","sourcesContent":[".navbar-base{\n    position: absolute;\n    width: 100%;\n    height: 64px;\n    left: 0px;\n    top: 0px;\n    background: #FFFFFF;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);\n    margin-bottom: 64px;\n\n}\n\n.logo-image{\n    margin: 0.5em;\n    padding-left: 20px;\n    width: 14em;\n}\n\n.image-container{\n}\n\n.appbar-text{\n    font-family: 'Noto Sans Display';\n    font-size: 30px;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
