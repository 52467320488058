import React, { useState, useEffect } from "react";
import { Button, Snackbar, IconButton, CloseIcon } from "@mui/material";

const MessageComponent = ({ messages }) => {
  const [showMessage, setShowMessage] = useState(true);
  useEffect(() => {
    if (messages.length > 0) {
      setShowMessage(true);
    }
  }, [messages]);

  // console.log("Render MessageComponent... messages: ", messages);
  // console.log("messages.length > 0: ", messages.length > 0);

  const onClose = () => {
    // console.log("calling onClose()...");
    setShowMessage(false);
  };

  if (messages.length === 0) return <></>;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        key={`bottom,left`}
        open={showMessage}
        autoHideDuration={6000}
        message={messages[0]}
        onClose={onClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              X
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};
export default MessageComponent;
